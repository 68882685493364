import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout/Layout'

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className='mt-12 mx-12 md:mx-40 xl:mx-60 md:mt-24 md:max-w-2xl'>
        {/* <h1 className='mb-4 font-sans font-bold text-2xl'>Hi</h1> */}
        <title>Not found</title>
        <p>
          Sorry{' '}
          <span role='img' aria-label='Pensive emoji'>
            😔
          </span>{' '}
          we couldn’t find what you were looking for.
          <br />
          <Link to='/'>Go home</Link>.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
